import { useTheme } from "@mui/material/styles"

type BreakpointValues<T> = {
    property: keyof T
    largeScreenValue: any
    smallScreenValue: any
}

export function useResponsiveProperty<T>(): (values: BreakpointValues<T>) => any {
    const theme = useTheme()

    return ({ property, largeScreenValue, smallScreenValue }) => {
        return {
            [property]: largeScreenValue,
            [theme.breakpoints.down("lg")]: {
                [property]: smallScreenValue,
            },
        }
    }
}
