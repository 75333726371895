/*global console:true*/
var DataHaptics = {
    /**
     * Just a very basic logging utility to avoid problems when console.log is not available
     * @type {DataHaptics.Logger}
     */
    Logger: (function () {
        //----- PUBLIC -----------------------------
        return {
            logInfo: function (message) {
                if (this.enabled && console && console.info) {
                    console.info.apply(console, ["[EMBEDDING DASHBOARDS] " + message])
                }
            },
            logWarning: function (message) {
                if (this.enabled && console && console.warn) {
                    console.warn.apply(console, ["[EMBEDDING DASHBOARDS] " + message])
                }
            },
            logError: function (message) {
                if (this.enabled && console && console.error) {
                    console.error.apply(console, ["[EMBEDDING DASHBOARDS] " + message])
                }
            },
        }
    })(),
}
export var MC = {
    externalMessages: {
        MESSAGE_SHARING_FORM_SAVE: "MESSAGE_SHARING_FORM_SAVE",
        MESSAGE_SHARING_FORM_CANCEL: "MESSAGE_SHARING_FORM_CANCEL",

        MESSAGE_SEGMENT_BUILDER_PREVIOUSTAB: "previousTab",
        MESSAGE_SEGMENT_BUILDER_NEXTTAB: "nextTab",
        MESSAGE_SEGMENT_BUILDER_SAVE: "save",
        MESSAGE_SEGMENT_BUILDER_SAVECOPY: "saveCopy",
        MESSAGE_SEGMENT_BUILDER_CANCEL: "cancel",

        //used by PHP core UI, be careful when changing these!---------------------------------------------

        //the advertiser tree selection was changed and the dashboard should be updated accordingly
        MESSAGE_CHANGE_APP_FILTER: "change-app-filter",
        MESSAGE_CHANGE_PATH: "change-app-path",

        //a "dashboard with filter" should be opened, such as the publisher details dashboard
        MESSAGE_DASHBOARD_OPEN_DASHBOARD_WITH_FILTER: "OPEN_DASHBOARD_WITH_FILTER",
        MESSAGE_DASHBOARD_OPEN_DASHBOARD_FOR_BACKEND_BASE_ID: "OPEN_DASHBOARD_FOR_BACKEND_BASE_ID",

        MESSAGE_DASHBOARD_APPLY_COLUMN_CONFIGURATOR_SETTINGS: "APPLY_COLUMN_CONFIGURATOR_SETTINGS",
    },
}

/**
 * Provides and API for embedding reporting pages within an iframe
 * It will be used by the core UI and the dasbboard based UI so be careful when editing something here.
 */
export const EasyXdmDashboardsEmbeddingAPI = (function () {
    //----- PRIVATE ----------------------------
    var crossDomainXhr
    var transports = {}
    var loggedIn = false
    var onAfterLogInCallbacks = [] //any actions that are waiting to be executed after the log in

    var options = {
        localHelperHtml: null,
        reportingServerUrl: null,
    }

    var setupMessageHandler = function (customHandler) {
        return function (message, origin) {
            var ret

            if (customHandler) {
                ret = customHandler(message, origin)
            }

            if (ret === false) {
                DataHaptics.Logger.logInfo(
                    "Don't execute default action for iframe message, because host handler prevented this.",
                )
                return
            }

            switch (message) {
                case "reload":
                    window.location.reload()
                    break
                default:
                // do nothing
            }
        }
    }

    /**
     * Create a DOM element
     * @param nodeName
     * @param name
     * @return {*}
     */
    var createElement = function (nodeName, name) {
        var node
        try {
            node = document.createElement("<" + nodeName + " name=" + name + ">")
        } catch (e) {
            node = document.createElement(nodeName)
            node.name = name
        }
        return node
    }

    var createContainerAndGetId = function (style) {
        var containerId = "frame-container-" + EasyXdmDashboardsEmbeddingAPI.getId()
        var containerEl = createElement("div", containerId)
        containerEl.setAttribute("id", containerId)
        containerEl.setAttribute("style", style || "")
        document.body.appendChild(containerEl)
        return containerId
    }

    var createHiddenContainerAndGetId = function () {
        return createContainerAndGetId(
            "border:0 none!important;position: absolute;left:-10000px;width:10px;height:10px;",
        )
    }

    /**
     * Create a new easyXDM transport IFrame
     * @param {String} remoteUrl
     * @param {String} containerId ID of an existing element into which the iframe will be rendered
     * @param {Function} onMessageHandler handler that will be executed as soon as a message has been received
     * @return {easyXDM.Socket}
     */
    var createTransportSocketAndIframe = function (remoteUrl, containerId, onMessageHandler) {
        var config = {
            local: options.localHelperHtml,
            container: containerId,
            remote: remoteUrl,
            remoteHelper: options.reportingServerUrl + "/frontend-dashboards/vendors/easyXDM/name.html",
            onMessage: setupMessageHandler(onMessageHandler),
        }
        DataHaptics.Logger.logInfo("NativeDashboardsEmbeddingAPI createTransportSocketAndIframe easyXDM.Socket:")
        for (var key in config) {
            if (config.hasOwnProperty(key)) {
                DataHaptics.Logger.logInfo(key + "=" + config[key])
            }
        }

        return new easyXDM.Socket(config)
    }

    var onAfterLogIn = function () {
        var i
        DataHaptics.Logger.logInfo("Executing Reporting onAfterLogIn action.")
        for (i = 0; i < onAfterLogInCallbacks.length; i++) {
            onAfterLogInCallbacks[i].call()
        }
        onAfterLogInCallbacks = []
    }

    //----- PUBLIC -----------------------------
    return {
        //----- CONSTANTS ----------------------------
        URLS: {
            URL_BUDGET_SCENARIO_BUILDER: "/core/budgetOptimization/scenarioBuilderView",
            URL_BUDGET_AUTO_OPTIMIZATION_RESULTS: "/core/budgetOptimization/autoOptimizationResultView",
        },

        MESSAGE_TYPES: {
            OPEN_DASHBOARD_WITH_FILTER: "OPEN_DASHBOARD_WITH_FILTER",
            OPEN_DASHBOARD_FOR_BACKEND_BASE_ID: "OPEN_DASHBOARD_FOR_BACKEND_BASE_ID",
            OPEN_DASHBOARD_GROUP: "OPEN_DASHBOARD_GROUP",
        },

        //----- FUNCTIONS ----------------------------
        getReportingServerUrl: function () {
            return EasyXdmDashboardsEmbeddingAPI.getConfiguration().reportingServerUrl
        },

        /**
         *
         * @param {String} socketName
         * @param {String} [componentId]
         * @param {String} message
         */
        sendMessage: function (socketName, componentId, message) {
            if (componentId) {
                if (transports[socketName] && transports[socketName][componentId]) {
                    transports[socketName][componentId].postMessage(message)
                }
            } else {
                if (transports[socketName]) {
                    transports[socketName].postMessage(message)
                }
            }
        },

        createSocketAndIframe: function (socketName, remoteUrl, containerId, onMessageHandler) {
            var socket
            socket = createTransportSocketAndIframe(remoteUrl, containerId, onMessageHandler)
            transports[socketName] = socket
        },

        /**
         * Check if the options have been initialized correctly
         * @return {Boolean}
         */
        areOptionsValid: function () {
            if (!options.reportingServerUrl) {
                DataHaptics.Logger.logError(
                    "Missing required config option reportingServerUrl. Please run " +
                        "NativeDashboardsEmbeddingAPI.setConfiguration('http://your.reporting.server.url.com', " +
                        "'http://thisserver.com/path/to/local/reportinghelper.html') first.",
                )
                return false
            }

            if (!options.localHelperHtml) {
                DataHaptics.Logger.logError(
                    "Missing required config option localHelperHtml. Please run " +
                        "NativeDashboardsEmbeddingAPI.setConfiguration('http://your.reporting.server.url.com', " +
                        "'http://thisserver.com/path/to/local/reportinghelper.html') first.",
                )
                return false
            }

            return true
        },

        /**
         * This need to be called before executing any other requests
         * @param {Object} cfg
         * @param {String} cfg.reportingServerUrl URL of the reporting server to be used
         * @param {String} cfg.localHelperHtml URL to the local reporting-helper.html file (the source server, not on the reporting server)
         * @param {String} cfg.mainSocketName
         */
        setConfiguration: function (cfg) {
            options.reportingServerUrl = cfg.reportingServerUrl
            if (typeof options.reportingServerUrl == "string") {
                if (options.reportingServerUrl.lastIndexOf("/") === options.reportingServerUrl.length - 1) {
                    options.reportingServerUrl = options.reportingServerUrl.substr(
                        0,
                        options.reportingServerUrl.length - 1,
                    )
                }
            }

            options.localHelperHtml = cfg.localHelperHtml
            options.debug = cfg.debug
        },

        /**
         * Returns the current configuration state as an object
         * @return {object} configuration object
         */
        getConfiguration: function () {
            return options
        },

        getId: function () {
            return Math.round(new Date().getTime() * Math.random() * 100)
        },

        /**
         * Executes the given action immediately if the user is already logged in to the reporting system or delayed after
         * the login if he is not yet logged in
         * @param action
         */
        executeWhenLoggedIn: function (action) {
            if (loggedIn) {
                action.call()
            } else {
                onAfterLogInCallbacks.push(action)
            }
        },

        /**
         * Trigger a log in and preloading of the required data to reduce waiting time for the user
         */
        logIn: function () {
            var remoteUrl, afterLogInMessageHandler, socket

            if (!EasyXdmDashboardsEmbeddingAPI.areOptionsValid()) {
                // if we want to use it for a keep alive we have to make a request every time
                // this is ok as logIn() ist called directly and not for embedding
                return
            }

            remoteUrl = options.reportingServerUrl + "/?preventStartup=true&embedded=true"
            afterLogInMessageHandler = function (message) {
                if (message == "logInSuccess") {
                    if (loggedIn) {
                        //no need to execute anything twice
                        return
                    }
                    loggedIn = true
                    //if the user has been logged in: trigger all actions that are waiting for the log in
                    onAfterLogIn()
                } else {
                    DataHaptics.Logger.logError("NativeDashboardsEmbeddingAPI: unable to log in. Message: " + message)
                }
            }
            //embed log in view into iFrame (iFrame will be created by easyXDM)
            socket = createTransportSocketAndIframe(
                remoteUrl,
                createHiddenContainerAndGetId(),
                afterLogInMessageHandler,
            )
            transports.loginTransportSocket = socket
        },

        isLoggedIn: function () {
            return loggedIn
        },

        createContainerElForEmbedding: function (domElementId) {
            var wrapperEl, containerId, containerAndIframeStyle, style

            wrapperEl = document.getElementById(domElementId)

            if (!wrapperEl || !wrapperEl.innerHTML) {
                //notifying the upper level that there's no container and we should wait and retry
                return false
            }

            containerId = "embedding-container-" + EasyXdmDashboardsEmbeddingAPI.getId()

            containerAndIframeStyle =
                "height:100%!important;width:100%!important;margin:0!important;border:0 none!important;"
            style =
                '<style type="text/css">' +
                "." +
                containerId +
                " {" +
                containerAndIframeStyle +
                "}" +
                "." +
                containerId +
                " iframe{" +
                containerAndIframeStyle +
                "}" +
                "</style>"

            //create the wrapper div that will show the loading gif immediately and create the required styles
            wrapperEl.innerHTML =
                style + '<div id="' + containerId + '" class="embedding-container ' + containerId + ' "></div>'

            return containerId
        },

        createAppFilterDTOFromAdvertiserTreeNavValues: function (navValues) {
            return {
                advertiserId: navValues.advertiserId,
                campaignId: navValues.exactagCampaignId,
                subCampaignId: navValues.exactagSubCampaignId,
                lineItemId: navValues.exactagLineItemId,

                advertiserName: navValues.advertiserMapping,
                campaignName: navValues.exactagCampaignMapping,
                subCampaignName: navValues.exactagSubCampaignMapping,
                lineItemName: navValues.exactagLineItemMapping,

                filters: [],
            }
        },

        /**
         * @param socketName name of the socket (so that the embedded component can be reached and updated later on; must be unique)
         * @param domElementId
         * @param appFilterDTO
         * @param dashboardSettings; supported settings include: singleDashboardName, lazyOpenAllDashboardsForDashboardGroup
         * @param onMessageHandler
         * @param callback
         */
        embedReporting: function (
            socketName,
            domElementId,
            appFilterDTO,
            dashboardSettings,
            onMessageHandler,
            callback,
        ) {
            EasyXdmDashboardsEmbeddingAPI.embedPage(
                options.reportingServerUrl + "/",
                socketName,
                domElementId,
                appFilterDTO,
                dashboardSettings,
                onMessageHandler,
                callback,
            )
        },

        embedCoreUI: function (
            url,
            socketName,
            domElementId,
            appFilterDTO,
            coreUiSettings,
            onMessageHandler,
            callback,
        ) {
            EasyXdmDashboardsEmbeddingAPI.embedPage(
                options.reportingServerUrl + url,
                socketName,
                domElementId,
                appFilterDTO,
                coreUiSettings,
                onMessageHandler,
                callback,
            )
        },

        /**
         * @param remoteUrl the URL to be embedded in the iFrame
         * @param socketName name of the socket (unique; used as a reference so that the embedded component can be reached and updated later on)
         * @param domElementId
         * @param appFilterDTO
         * @param settings
         * @param onMessageHandler
         * @param callback
         */
        embedPage: function (remoteUrl, socketName, domElementId, appFilterDTO, settings, onMessageHandler, callback) {
            var embedPageAction, that

            if (!EasyXdmDashboardsEmbeddingAPI.areOptionsValid()) {
                return
            }

            // eslint-disable-next-line  @typescript-eslint/no-this-alias
            that = this

            embedPageAction = function () {
                var containerId,
                    step = 0,
                    delay = 1000,
                    urlParams = "",
                    //try to create the container element maximum 10 times, otherwise log the error
                    recursiveCreateContainer = function (step, delay) {
                        containerId = EasyXdmDashboardsEmbeddingAPI.createContainerElForEmbedding(domElementId)
                        if (!containerId) {
                            if (step < 10) {
                                DataHaptics.Logger.logInfo("Missing wrapperEl, trying to create it again...")
                                step++
                                setTimeout(function () {
                                    recursiveCreateContainer(step, delay)
                                }, delay)
                            } else {
                                //log error, that there's no wrapping element
                                DataHaptics.Logger.logError("Missing wrapperEl.")
                            }
                        } else {
                            remoteUrl = remoteUrl + (remoteUrl.indexOf("?") >= 0 ? "&" : "?") + "embedded=true"

                            for (var key in settings) {
                                if (settings.hasOwnProperty(key)) {
                                    urlParams += "&" + key + "=" + settings[key]
                                }
                            }

                            urlParams += "&appFilterDTO=" + encodeURIComponent(JSON.stringify(appFilterDTO))

                            remoteUrl = remoteUrl + urlParams

                            DataHaptics.Logger.logInfo("RemoteUrl to be embedded is: " + remoteUrl)

                            that.createSocketAndIframe(socketName, remoteUrl, containerId, onMessageHandler)

                            if (callback) {
                                callback()
                            }
                        }
                    }
                recursiveCreateContainer(step, delay)
            }

            embedPageAction()
        },

        /* #### SEGMENT BUILDER ###################*/

        /**
         * Embed the segment builder into an already existing DOM element
         * @param {String} domElementId Unique ID of an existing DOM element; will be the target element where the iframe will be embedded
         * @param {String} campaignId Id of the campaign of this segment.
         * @param {String} [lineItemId] Id of the line item of this segment (optional).
         * @param {String} [segmentId] Id of the segment to edit or undefined if it's a new segment.
         * @param {object} [messageCallback] Function to execute when massages from segment builder are received.
         */
        embedSegmentBuilder: function (domElementId, campaignId, lineItemId, segmentId, messageCallback) {
            var embedSegmentBuilderAction

            if (!this.areOptionsValid()) {
                return
            }

            // eslint-disable-next-line  @typescript-eslint/no-this-alias
            var that = this

            embedSegmentBuilderAction = function () {
                var containerId, remoteUrl
                containerId = that.createContainerElForEmbedding(domElementId)

                //embed widget into iFrame (iFrame will be created by easyXDM)
                remoteUrl = that.getReportingServerUrl() + "/mc/segmentbuilder/"

                if (segmentId) {
                    remoteUrl += "edit/?embedded=true&id=" + segmentId
                } else {
                    remoteUrl += "create/?embedded=true"
                }

                if (campaignId) {
                    remoteUrl += "&campaign_id=" + campaignId
                }
                if (lineItemId) {
                    remoteUrl += "&line_item_id=" + lineItemId
                }

                that.createSocketAndIframe("segmentBuilderTransportSocket", remoteUrl, containerId, messageCallback)
            }
            this.executeWhenLoggedIn(embedSegmentBuilderAction)
        },

        sendSegmentBuilderAction: function (action) {
            this.sendMessage(
                "segmentBuilderTransportSocket",
                undefined,
                JSON.stringify({ segmentbuilder: { action: action } }),
            )
        },

        /* #### FILTERS ###################*/
        /**
         * Change app filter of running socket.
         *
         * @param {String} socketName of socket
         * @param {Object[]} appFilterDTO to send
         */
        changeAppFilter: function (socketName, appFilterDTO) {
            if (!EasyXdmDashboardsEmbeddingAPI.areOptionsValid() || !transports[socketName] || !appFilterDTO) {
                return
            }

            transports[socketName].postMessage(
                JSON.stringify({
                    type: MC.externalMessages.MESSAGE_CHANGE_APP_FILTER,
                    body: appFilterDTO,
                }),
            )
        },

        applyColumnConfiguratorSettings: function (socketName, columnConfiguratorConfig) {
            if (!EasyXdmDashboardsEmbeddingAPI.areOptionsValid() || !transports[socketName]) {
                return
            }

            transports[socketName].postMessage(
                JSON.stringify({
                    type: MC.externalMessages.MESSAGE_DASHBOARD_APPLY_COLUMN_CONFIGURATOR_SETTINGS,
                    body: columnConfiguratorConfig,
                }),
            )
        },
    }
})()
