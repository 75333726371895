import {
    ASONewUiMenuLeafDTO,
    ClassicUiEmbeddedMenuLeafDTO,
    DashboardConfigurationToolLeafDTO,
    GroupDashboardMenuLeafDTO,
    LegacyDashboardsEmbeddedMenuLeafDTO,
    MenuDTO,
    NewUiGenericPageMenuLeafDTO,
    SingleDashboardMenuLeafDTO,
} from "generated/models"

export enum ContentType {
    HELP = "HELP",
    NEWS = "NEWS",
}

export interface MenuLeafDTO {
    title: string
    path: string
    icon?: string
    treeType:
        | "NewUiGenericPageMenuLeafDTO"
        | "LegacyDashboardsEmbeddedMenuLeafDTO"
        | "SingleDashboardMenuLeafDTO"
        | "DashboardConfigurationToolLeafDTO"
        | "GroupDashboardMenuLeafDTO"
        | "ClassicUiEmbeddedMenuLeafDTO"
        | "ASONewUiMenuLeafDTO"
    campaignSelectionRequired: boolean
    hidden: boolean
}

export const isNewUiMenuLeaf = (menuDTO: MenuDTO): menuDTO is NewUiGenericPageMenuLeafDTO => {
    return menuDTO.treeType === "NewUiGenericPageMenuLeafDTO"
}
export const isASONewUiMenuLeaf = (menuDTO: MenuDTO): menuDTO is ASONewUiMenuLeafDTO => {
    return menuDTO.treeType === "ASONewUiMenuLeafDTO"
}

export const isLegacyDashboardsEmbeddedMenuLeaf = (
    menuDTO: MenuDTO,
): menuDTO is LegacyDashboardsEmbeddedMenuLeafDTO => {
    return menuDTO.treeType === "LegacyDashboardsEmbeddedMenuLeafDTO"
}

export const isDashboardConfigurationToolLeaf = (menuDTO: MenuDTO): menuDTO is DashboardConfigurationToolLeafDTO => {
    return menuDTO.treeType === "DashboardConfigurationToolLeafDTO"
}

export const isSingleDashboardMenuLeaf = (menuDTO: MenuDTO): menuDTO is SingleDashboardMenuLeafDTO => {
    return menuDTO.treeType === "SingleDashboardMenuLeafDTO"
}

export const isGroupDashboardMenuLeaf = (menuDTO: MenuDTO): menuDTO is GroupDashboardMenuLeafDTO => {
    return menuDTO.treeType === "GroupDashboardMenuLeafDTO"
}

export const isClassicUiEmbeddedMenuLeaf = (menuDTO: MenuDTO): menuDTO is ClassicUiEmbeddedMenuLeafDTO => {
    return menuDTO.treeType === "ClassicUiEmbeddedMenuLeafDTO"
}

export enum ActionIdentifier {
    CREATE = "CREATE",
    EDIT = "EDIT",
    DELETE = "DELETE",
    DEACTIVATE = "DEACTIVATE",
    ACTIVATE = "ACTIVATE",
    DOWNLOAD = "DOWNLOAD",
}
