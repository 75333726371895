import { ContentType } from "domain/types"
import { MenuRootDTO } from "generated/models"
import { get } from "shared/service"
import UrlService from "shared/service/url.service"

const loadArticleContent = async (path: string): Promise<string> => {
    const filename = path.substring(path.lastIndexOf("/") + 1, path.length)
    return get(`${UrlService.getUiServiceApiUrl()}/static${path}/${filename}.html`)
}

const loadMenu = async (type: ContentType): Promise<MenuRootDTO> =>
    get(`${UrlService.getUiServiceApiUrl()}/static/getArticleMenu/${type}`)

const loadLatestArticles = (type: ContentType, count: number): Promise<LatestArticlesResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/static/getLatestArticles/${type}/${count}`)
}

export type LatestArticlesResponseDTO = {
    articles: ArticleDTO[]
}

export type ArticleDTO = {
    title: string
    content: string
}

export const ArticleService = {
    loadArticleContent: loadArticleContent,
    loadMenu: loadMenu,
    loadLatestArticles: loadLatestArticles,
}
