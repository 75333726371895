import { EasyXdmClassicUiEmbeddingAPI } from "embedding/classicui/classicui_embedding_api"
import { AppContextDTO } from "generated/models"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import React, { type JSX, useEffect } from "react"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import { useClassicUiContext } from "shared/component/ClassicUiEmbeddingContextProvider"
import { log } from "shared/util/log"

declare const baseUrlFrontend: string
declare const window: any

const socketName = "socket-newui-classicui"
const containerId = "iframe-newui-classicui"

interface Props {
    url: string
    appContextDTO: AppContextDTO
}

const ClassicUiEmbeddingAPI: React.FC<Props> = (props: Props): JSX.Element => {
    const classicUiContext = useClassicUiContext()
    const analyticsService = useAnalytics()

    useEffect(() => {
        EasyXdmClassicUiEmbeddingAPI.setConfiguration({
            localHelperHtml: `${baseUrlFrontend}/asset/js/easyXDM/name.html`,
        })

        EasyXdmClassicUiEmbeddingAPI.embedPage(
            props.url + "/",
            socketName,
            containerId,
            props.appContextDTO,
            {},
            onMessageHandler,
        )
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Reacts on changes of the href in the classicUiContext. It sends the new href to the classic ui.
     */
    useEffect(() => {
        if (classicUiContext.href !== undefined) {
            EasyXdmClassicUiEmbeddingAPI.switchToHref(socketName, classicUiContext.href)
        }
    }, [classicUiContext.href])

    /**
     * Reacts on appContextDTO changes. It sends the new appContextDTO to the classic ui.
     */
    useEffect(() => {
        EasyXdmClassicUiEmbeddingAPI.changeAppContext(socketName, props.appContextDTO)
    }, [props.appContextDTO])

    /**
     * Handles messages from the classic ui
     *
     * @param message
     * @param originUrl
     */
    const onMessageHandler = (message: string, originUrl: string) => {
        log.debug("Received message:" + message + ", origin:" + originUrl)

        if (message === "reload") {
            log.error("Can not load the classic ui, reload message received")
            // return false to prevent reloading the page
            return false
        }

        let messageJson
        try {
            messageJson = JSON.parse(message)
        } catch (error) {
            log.error("Error while parsing message json", error)
            return
        }

        switch (messageJson.type) {
            case "login":
                if (messageJson.body.message === "logInSuccess") {
                    log.info("ClassicUi logged in successfully")

                    // store classicui uid in the window object so that the SLA check can access it
                    window.ClassicUiEmbeddedUid = messageJson.body.uid
                } else {
                    log.error("ClassicUi login failed", messageJson.body.message)
                }
                break
            case "classicUiStartedUp":
                classicUiContext.setLoggedIn(true)
                break
            case "classicUiTrackEvent":
                analyticsService.trackCustomEvent(messageJson.body.eventName, messageJson.body.eventData)
                break
            default:
                log.warn(`Unsupported message type ${messageJson.type}`)
                break
        }
    }

    return (
        <div id={containerId} style={{ width: "100%", height: "100%" }}>
            <MainContentAreaLoadingMask />
        </div>
    )
}

export default ClassicUiEmbeddingAPI
