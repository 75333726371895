import { Box, CircularProgress } from "@mui/material"
import { DRAWER_WIDTH_EXPANDED, TOP_BAR_HEIGHT } from "layout/MainLayout/constants"
import React, { type JSX } from "react"

export type MainContentAreaLoadingMaskProps = {
    withAdditionalOffset?: boolean
}

export const MainContentAreaLoadingMask = (props: MainContentAreaLoadingMaskProps): JSX.Element => {
    return (
        <Box
            className="main-content-area-loading-mask"
            sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                top: props.withAdditionalOffset ? TOP_BAR_HEIGHT : 0,
                left: DRAWER_WIDTH_EXPANDED,
            }}
        >
            <div className="screen-centered">
                <CircularProgress />
            </div>
        </Box>
    )
}

export default MainContentAreaLoadingMask
