export const EasyXdmClassicUiEmbeddingAPI = (function () {
    const transports = {}
    // let loggedIn = false
    var onAfterLogInCallbacks = [] //any actions that are waiting to be executed after the log in

    const options = {
        localHelperHtml: null,
    }

    /**
     * Create a new easyXDM transport IFrame
     * @param {String} remoteUrl
     * @param {String} containerId ID of an existing element into which the iframe will be rendered
     * @param {Function} onMessageHandler handler that will be executed as soon as a message has been received
     * @return {easyXDM.Socket}
     */
    const createTransportSocketAndIframe = function (remoteUrl, containerId, onMessageHandler) {
        var config = {
            local: options.localHelperHtml,
            container: containerId,
            remote: remoteUrl,
            remoteHelper: options.localHelperHtml,
            onMessage: setupMessageHandler(onMessageHandler),
        }
        DataHaptics.Logger.logInfo("EmbeddingAPI createTransportSocketAndIframe easyXDM.Socket:")
        for (var key in config) {
            if (config.hasOwnProperty(key)) {
                DataHaptics.Logger.logInfo(key + "=" + config[key])
            }
        }

        return new easyXDM.Socket(config)
    }

    const setupMessageHandler = function (customHandler) {
        return function (message, origin) {
            var ret

            if (customHandler) {
                ret = customHandler(message, origin)
            }

            if (ret === false) {
                DataHaptics.Logger.logInfo(
                    "Don't execute default action for iframe message, because host handler prevented this.",
                )
                return
            }

            switch (message) {
                case "reload":
                    window.location.reload()
                    break
                default:
                // do nothing
            }
        }
    }

    return {
        setConfiguration: function (cfg) {
            options.localHelperHtml = cfg.localHelperHtml
        },

        embedPage: function (remoteUrl, socketName, domElementId, appFilterDTO, settings, onMessageHandler, callback) {
            var embedPageAction, that

            if (!EasyXdmClassicUiEmbeddingAPI.areOptionsValid()) {
                return
            }

            // eslint-disable-next-line  @typescript-eslint/no-this-alias
            that = this

            embedPageAction = function () {
                var containerId,
                    step = 0,
                    delay = 1000,
                    urlParams = "",
                    //try to create the container element maximum 10 times, otherwise log the error
                    recursiveCreateContainer = function (step, delay) {
                        containerId = EasyXdmClassicUiEmbeddingAPI.createContainerElForEmbedding(domElementId)
                        if (!containerId) {
                            if (step < 10) {
                                DataHaptics.Logger.logInfo("Missing wrapperEl, trying to create it again...")
                                step++
                                setTimeout(() => recursiveCreateContainer(step, delay), delay)
                            } else {
                                //log error, that there's no wrapping element
                                DataHaptics.Logger.logError("Missing wrapperEl.")
                            }
                        } else {
                            remoteUrl = remoteUrl + (remoteUrl.indexOf("?") >= 0 ? "&" : "?") + "embedded=true"

                            for (const key in settings) {
                                if (settings.hasOwnProperty(key)) {
                                    urlParams += "&" + key + "=" + settings[key]
                                }
                            }

                            urlParams += "&appFilterDTO=" + encodeURIComponent(JSON.stringify(appFilterDTO))

                            remoteUrl = remoteUrl + urlParams

                            DataHaptics.Logger.logInfo("RemoteUrl to be embedded is: " + remoteUrl)

                            that.createSocketAndIframe(socketName, remoteUrl, containerId, onMessageHandler)

                            if (callback) {
                                callback()
                            }
                        }
                    }
                recursiveCreateContainer(step, delay)
            }

            embedPageAction()
        },

        areOptionsValid: function () {
            if (!options.localHelperHtml) {
                DataHaptics.Logger.logError("Missing required config option localHelperHtml.")
                return false
            }

            return true
        },

        createContainerElForEmbedding: function (domElementId) {
            var wrapperEl, containerId, containerAndIframeStyle, style

            wrapperEl = document.getElementById(domElementId)

            if (!wrapperEl || !wrapperEl.innerHTML) {
                //notifying the upper level that there's no container and we should wait and retry
                return false
            }

            containerId = "embedding-container-" + EasyXdmClassicUiEmbeddingAPI.getId()

            containerAndIframeStyle =
                "height:100%!important;width:100%!important;margin:0!important;border:0 none!important;"
            style =
                '<style type="text/css">' +
                "." +
                containerId +
                " {" +
                containerAndIframeStyle +
                "}" +
                "." +
                containerId +
                " iframe{" +
                containerAndIframeStyle +
                "}" +
                "</style>"

            //create the wrapper div that will show the loading gif immediately and create the required styles
            wrapperEl.innerHTML =
                style + '<div id="' + containerId + '" class="embedding-container ' + containerId + ' "></div>'

            return containerId
        },

        getId: function () {
            return Math.round(new Date().getTime() * Math.random() * 100)
        },

        /**
         *
         * @param {String} socketName
         * @param {String} [componentId]
         * @param {String} message
         */
        sendMessage: function (socketName, componentId, message) {
            if (componentId) {
                if (transports[socketName] && transports[socketName][componentId]) {
                    transports[socketName][componentId].postMessage(message)
                }
            } else {
                if (transports[socketName]) {
                    transports[socketName].postMessage(message)
                }
            }
        },

        switchToHref: function (socketName, href) {
            EasyXdmClassicUiEmbeddingAPI.sendMessage(
                socketName,
                undefined,
                JSON.stringify({
                    type: "change-menu-entry",
                    body: {
                        href: href,
                    },
                }),
            )
        },

        changeAppContext: function (socketName, appContext) {
            EasyXdmClassicUiEmbeddingAPI.sendMessage(
                socketName,
                undefined,
                JSON.stringify({
                    type: "change-app-filter",
                    body: appContext,
                }),
            )
        },

        createSocketAndIframe: function (socketName, remoteUrl, containerId, onMessageHandler) {
            var socket
            socket = createTransportSocketAndIframe(remoteUrl, containerId, onMessageHandler)
            transports[socketName] = socket
        },
    }
})()
